h2.toptweet
{
	padding-top: 10px;
 font-size: 18px;
  font-weight: 700;	
  border-bottom: solid 1px #eee;
  padding-bottom: 10px;
}
.tweet
{

}
p.tweettext
{
  font-size: 16px;
  font-weight: 600;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}
 
.react-player {
  width: 100%;
}
.tweetbottom
{
  color: #333;
  font-size: 13px;
  border-top: solid 1px #eee;
  padding-top: 10px;
  letter-spacing: -0.5px;
}
.tweetbottom img
{
  height: 20px;
}
.card
{
  margin-bottom: 10px;
}
.navbar
{
  margin-bottom: 20px;
}